.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.header {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.btn-link {
  padding: 0px !important;
  margin: 0px !important;
}

ul.zamci {
  list-style: none;
  padding: 0px;
}
ul.zamci li {
  padding: 10px;
  border-top: solid 1px #ddd;
}
ul.zamci li.selected {
  background-color: #efefef;
}
ul.zamci div {
  font-size: 0.8em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-90w .modal-dialog {
  width: 90% !important;
  max-width: 90% !important;
}
